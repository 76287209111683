import React from 'react';
import { Button, Popconfirm, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { iGrupoCliente } from '../../../Types/Types';
import { FaPen, FaTrash } from 'react-icons/fa';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '../../../helpers/axios';

type TableProps = {
    Data: iGrupoCliente[];
    setData: Function;
    edit: Function;
};

export default function TableGrupo({ Data, setData, edit }: TableProps) {
    async function startDelete(id: string) {
        await api.delete(`/grupoCliente/${id}`)
            .then((response) => {
                toast.success("Usuário excluido com Sucesso !")
                // console.log(response);
                setData(Data.filter(item => item._id !== id));
            })
            .catch((error: AxiosError) => {
                console.error(error);
                toast.error(error.message);
            });
    }
    const columns: ColumnsType<iGrupoCliente> = [
        {
            title: 'Nome Grupo',
            dataIndex: 'nome',
            key: 'nome',
            width: '5%',
        },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            key: 'email',
            width: '5%',
        },
        {
            title: 'Site',
            dataIndex: 'idEmpresa.nome',
            key: 'empresa',
            width: '10%',
            render: (_, { idSite }) => (
                <>
                    {idSite.nome}
                </>
            ),
        }, 
        {
            title: 'Grupos',
            dataIndex: 'idGrupo.__id',
            key: 'idGrupo',
            width: '10%',
            render: (_, { cor }) => (
                <>
                   
               <Tag color={cor} className='m-1'></Tag>
                   
                </>
            ),
        },
        {
            title: '',
            key: 'action',
            width: '1%',
            render: (_, row) => (
                <Space size="middle">
                    <Button type="text" onClick={()=>edit(row._id)}><FaPen /></Button>
                    <Popconfirm
                        title="Deletar Usuario !"
                        description="Tem certeza que deseja deletar ?"
                        onConfirm={() => startDelete(row._id)}
                        // onCancel={handleOpenCloseModalConfirm}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="text" danger><FaTrash /></Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    return (
        <Table columns={columns} dataSource={Data} />
    )
}
