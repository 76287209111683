import { ReactElement, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { BiMenu, BiMessageDetail } from 'react-icons/bi';
import { BsX, BsPostcard, BsCardText, BsImages, BsBoxSeamFill, BsPersonFillGear, BsShieldLock } from "react-icons/bs";
import { AiOutlineHome, AiOutlineUser, AiFillSetting } from "react-icons/ai";
import { RiPagesLine } from "react-icons/ri";
import { MdOutlineBusiness } from "react-icons/md";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import "./style.scss"
import Button from "antd/es/button";
import { FaPuzzlePiece } from "react-icons/fa";
import { userContext } from "../../context/usuarioContext";
import Item from "antd/es/list/Item";
import { iUserData } from "../../Types/Types";
import { ADMID, isAdm } from "../utils/exportVariables";
// interface Props {
//     children?: ReactNode;
// }
interface iSubMenu {
    title: string;
    href: string;
    icon: ReactElement;
    role: string[];
}
interface iMenu {
    title: string;
    menus: iSubMenu[];
    role: string[];
}
interface SideBarProps {
    IconHoverColor?: string | "bg-red-600";
    SideBarHoverColor?: string;
    AsideBackGroundColor?: string;
    asideOpen: boolean;
    setAsideOpen: Function;
}
export default function SideBar({ IconHoverColor, SideBarHoverColor, asideOpen, setAsideOpen }: SideBarProps) {
    const { user } = useContext(userContext);
    const admSite = "649eeb0aba394ede4c21d8b8";

    const asideRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (asideRef.current && !asideRef.current.contains(event.target as Node)) {
                setAsideOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [asideRef]);

    function returnMenu(menu: iMenu[], usuario: iUserData | undefined): iMenu[] {
        if (usuario?.idPermissao) {
            if (isAdm()) {
                return menu;
            } else {
                return menu.filter(item => item.role.indexOf(ADMID) === -1);
            }
        } else {
            return [];
        }

    }
    const menu: iMenu[] = [
        {
            title: "Site",
            role: [admSite],
            menus: [
                {
                    title: "Dashboard",
                    href: "/dashboard",
                    icon: <AiOutlineHome />,
                    role: [""],
                },
                {
                    title: "Usuario",
                    href: "/usuario",
                    icon: <AiOutlineUser />,
                    role: [admSite],
                },
                // {
                //     title: "Posts",
                //     href: "/Posts",
                //     icon: <BsPostcard />
                // },
                // {
                //     title: "Textos",
                //     href: "/Textos",
                //     icon: <BsCardText />
                // },
                {
                    title: "Imagens",
                    href: "/Imagens",
                    icon: <BsImages />,
                    role: [admSite],
                },
                {
                    title: "Mensageria",
                    href: "/Mensageria",
                    icon: <BiMessageDetail />,
                    role: [admSite],
                },
                {
                    title: "Clientes",
                    href: "/Clientes",
                    icon: <BsPersonFillGear />,
                    role: [admSite],
                },                
                {
                    title: "Produtos",
                    href: "/Produtos",
                    icon: <BsBoxSeamFill />,
                    role: [admSite],
                },
                {
                    title: "Configuração",
                    href: "/Configuracao",
                    icon: <AiFillSetting />,
                    role: [""],
                },
            ]
        },
        {
            title: "Admin",
            role: [ADMID],
            menus: [
                {
                    title: "Sites",
                    href: "/sites",
                    icon: <RiPagesLine />,
                    role: [ADMID],
                },
                {
                    title: "Módulos",
                    href: "/modulos",
                    icon: <FaPuzzlePiece />,
                    role: [ADMID],
                },
                {
                    title: "Contratantes",
                    href: "/contratantes",
                    icon: <MdOutlineBusiness />,
                    role: [ADMID],
                },
                {
                    title: "Permissões",
                    href: "/permissoes",
                    icon: <BsShieldLock />,
                    role: [ADMID],
                },
            ]
        },
    ]

    return (
        <>
            <aside ref={asideRef} className={`fixed z-50 top-0 bg-[#1d2538] min-h-full flex flex-col items-center pt-5 pb-2 space-y-7 ${asideOpen ? 'w-44' : 'w-0'} transition-max-w ease-in-out duration-300`}>
                {/* <!-- menu items --> */}
                <div className={` w-full pr-3 flex flex-col gap-y-1 text-gray-500 fill-gray-500 text-sm items-end`}>
                    <button id="btnSidebarToggler" type="button" className="bg-transparent border-0 cursor-pointer p-4 text-2xl text-white hover:text-gray-200 text-right h-fit w-fit" onClick={() => setAsideOpen(!asideOpen)}>
                        <BsX />
                    </button>
                </div>
                {
                    returnMenu(menu, user).map((item,index) => {
                        return (
                            <div key={index + 4} className={`w-full pr-3 flex flex-col gap-y-1 text-gray-500 fill-gray-500 text-sm ${asideOpen ? 'mr-0' : 'mr-[300px]'} ease-in-out duration-300`}>
                                <div className="font-QuicksandMedium pl-4 text-gray-400/60 text-xs text-[11px] uppercase">{item.title}</div>
                                {
                                    item.menus.map((menu,ind) => {
                                        return (
                                            <Link to={menu.href} key={ind + 4}>
                                                <div className="w-full flex items-center gap-x-1.5 group select-none menuHv">
                                                    <div className="w-1 rounded-xl h-8 bg-transparent transition-colors duration-200 relative overflow-hidden">
                                                        <div
                                                            className={`absolute top-0 left-0 w-full h-[102%] ${location.pathname === menu.href ? 'translate-y-0 bg-red-600 transition-all duration-300' : 'menu bg-red-600 transition-all duration-300 '}`}
                                                        >

                                                        </div>
                                                    </div>

                                                    <div className={`${location.pathname === menu.href ? 'bg-white/10 text-white' : ''} pt-1.5 group-hover:bg-white/10 w-full group-active:scale-95 self-stretch pl-2 rounded flex items-center space-x-2 transition-all duration-200 dark:group-hover:text-white dark:hover:text-white text-sm`}>
                                                        {/* <AiOutlineHome className={`${location.pathname === menu.href ? '!fill-red-600' : ''} h-5 w-5 group-hover:fill-red-600 dark:fill-gray-600 transition-colors duration-200`}/> */}

                                                        {React.cloneElement(menu.icon, { className: `${location.pathname === menu.href ? '!fill-red-600' : ''} h-5 w-5 group-hover:fill-red-600 dark:fill-gray-600 transition-colors duration-200` })}
                                                        <span className="font-QuicksandMedium">{menu.title}</span>
                                                    </div>
                                                </div>
                                            </Link>
                                        );
                                    })
                                }
                            </div>
                        );
                    })
                }
            </aside>
        </>
    );
}