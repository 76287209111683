import React, { useContext, useState } from "react";
import MainCard from "../../../Components/MainCard";
import { Button, Collapse, Divider, Drawer, Form, Input, InputNumber, Space, Upload, UploadFile } from "antd";
import { IProdutoData } from "../../../Types/Types";
import { toast } from "react-toastify";
import SelectSites from "../../../Components/Select/selectSites";
import api from "../../../helpers/axios";
import { isAdm } from "../../../Components/utils/exportVariables";
import { userContext } from "../../../context/usuarioContext";
import ProdutosList from "../../../Components/Table/ProdutosList";
import { UploadOutlined } from '@ant-design/icons';
import { UploadChangeParam } from "antd/es/upload";

type listaProdutos = {
    categoria: string;
    produtos: IProdutoData[];
}

export default function Produtos() {
    const { user } = useContext(userContext);
    const [isAdmin, setIsAdmin] = useState(isAdm() || false);
    const [tableData, setTableData] = React.useState<listaProdutos[]>([]);
    const [imagemBase64, setImagemBase64] = React.useState<string | null>(null);
    const [nome, setNome] = useState<string>("");
    const [categoria, setCategoria] = useState<string>("");
    const [codigo, setCodigo] = useState<string>("");
    const [quantidade, setQuantidade] = useState<string>("");
    const [marca, setMarca] = useState<string>("");
    const [descricao, setDescricao] = useState<string>("");
    const [preco, setPreco] = useState<number>(0);
    const [atualizaID, setAtualizaID] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [idSite, setIdSite] = React.useState<string>("");

    React.useEffect(() => {
        if (user && user?._id)
            api.get(user.isADM ? '/produtosPorCategoria' : `/produtosPorCategoriaSite/${user?.idSite._id}`).then((response) => {
                console.log(response.data)
                setTableData(response.data);
            });
    }, [user]);

    const [isDrawerOpen, setisDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        clearInputs();
        setIsEdit(false);
        setisDrawerOpen(!isDrawerOpen);
    };


    function edit(id: string) {
        setIsEdit(true);
        api.get(`/produtos/${id}`).then((response) => {
            const res = response.data as IProdutoData;
            console.log("responseData", response.data)
            const grupos: string[] = [];
            setAtualizaID(res._id.toString());
            setNome(res.nome);
            setCodigo(res.codigo);
            setMarca(res.marca);
            setCategoria(res.categoria);
            setDescricao(res.descricao);
            setQuantidade(res.quantidade);
            setImagemBase64(res.imagem);
            setPreco(res.preco);
            setIdSite(res.idSite as unknown as string);
        });
        setisDrawerOpen(true);
    }
    function cadastro() {
        return api.post('/produtos', {
            nome: nome,
            codigo: codigo,
            marca: marca,
            imagem: imagemBase64,
            categoria: categoria,
            quantidade: quantidade,
            preco: preco,
            descricao: descricao,
            ativo: true,
            idSite: user && user.isADM ? idSite : user?.idSite._id,
        });
    }
    function atualizar() {
        return api.put(`/produtos/${atualizaID}`, {
            nome: nome,
            codigo: codigo,
            marca: marca,
            imagem: imagemBase64,
            categoria: categoria,
            quantidade: quantidade,
            preco: preco,
            descricao: descricao,
            idSite: user && user.isADM ? idSite : user?.idSite._id,
        });
    }
    async function OnSubmit() {
        if (!isEdit) {
            try {
                const resposta = await cadastro();
                toast.success("Cadastrado com Sucesso");
                toggleDrawer();
            } catch (error) {
                toast.error(`${error}`);
            }
        } else {
            try {
                const resposta = await atualizar();
                toast.success("Atualizado com Sucesso");
                toggleDrawer();
            } catch (error) {
                toast.error(`${error}`);
            }
            setIsEdit(false);
        }
        if (user && user?._id)
            api.get(user.isADM ? '/produtosPorCategoria' : `/produtosPorCategoriaSite/${user?.idSite._id}`).then((response) => {
                console.log(response.data)
                setTableData(response.data);
            });
    }
    function uploadFile(event: UploadChangeParam<UploadFile<any>>) {
        if (event.fileList.length > 0) {
            const file = event.fileList[0].originFileObj as File;
            const reader = new FileReader();
            reader.onload = () => {
                const base64String = reader.result as string;
                setImagemBase64(base64String);
            };
            reader.readAsDataURL(file);
        } else {
            setImagemBase64(null);
        }
    }
    function clearInputs() {
        setIdSite("");
        setNome("");
        setCodigo("");
        setMarca("");
        setCategoria("");
        setQuantidade("");
        setDescricao("");
        setImagemBase64("");
        setPreco(0);
    }
    const { Panel } = Collapse;
    return (
        <MainCard title="Produtos">
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 text-right">
                    <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
                        Novo Produto
                    </Button>
                </div>
                <div className="col-span-4">
                    {
                        tableData.map((item, index) => (
                            <section key={index + 1}>
                                {/* <Divider orientation="left" className='py-10'>{item.categoria}</Divider> */}
                                <Collapse defaultActiveKey={tableData[0].categoria} ghost size="large">
                                    <Panel header={<>{item.categoria} <hr /></>} key={item.categoria}>
                                        <ProdutosList Data={item.produtos} setData={setTableData} editFuncion={edit} />
                                    </Panel>
                                </Collapse>
                            </section>
                        ))
                    }

                </div>
            </div>
            <Drawer
                title="Cadastro de Produtos"
                placement="right"
                onClose={toggleDrawer}
                open={isDrawerOpen}
                extra={
                    <Space>
                        <Button onClick={toggleDrawer}>Cancel</Button>
                        <Button htmlType="submit" form="FromCad" type="primary">
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    onFinish={(e) => { OnSubmit(); }}
                    id="FromCad"
                >
                    <Form.Item label="Nome" rules={[{ required: true }]}>
                        <Input size="large" id="Nome" placeholder="Nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Item>

                    <Form.Item label="Código" >
                        <Input size="large" id="Codigo" placeholder="Codigo" type="text" value={codigo} onChange={(e) => setCodigo(e.target.value)} />
                    </Form.Item>

                    <Form.Item label="Marca" >
                        <Input size="large" id="Marca" placeholder="Marca" type="text" value={marca} onChange={(e) => setMarca(e.target.value)} />
                    </Form.Item>

                    <Form.Item label="Categoria" >
                        <Input size="large" id="Categoria" placeholder="Categoria" type="text" value={categoria} onChange={(e) => setCategoria(e.target.value)} />
                    </Form.Item>

                    <Form.Item label="Quantidade" >
                        <Input size="large" id="Quantidade" placeholder="Quantidade" type="text" value={quantidade} onChange={(e) => setQuantidade(e.target.value)} />
                    </Form.Item>

                    <Form.Item label="Descrição" >
                        <Input id="descricao" size="large" placeholder="Descrição" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
                    </Form.Item>

                    <Form.Item label="Preço" >
                        <InputNumber style={{ width: "100%" }} prefix="R$" id="preco" size="large" placeholder="Preço" type="number" value={preco} onChange={(e) => setPreco(Number(e))} />
                    </Form.Item>

                    <Form.Item label="Imagem" >
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            onChange={uploadFile}
                            beforeUpload={() => false}
                            maxCount={1}
                        >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>
                    {
                        user && user.isADM ?
                            <Form.Item label="Site" >
                                <SelectSites value={idSite} setValue={setIdSite} />
                            </Form.Item>
                            :
                            null
                    }


                </Form>
            </Drawer>
        </MainCard>

    )
}