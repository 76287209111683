import React, { useContext, useState } from "react";
import MainCard from "../../../Components/MainCard";
import axios from "axios";
import ClienteTabela from "../../../Components/Table/ClienteTable";
import { Button, Card, DatePicker, DatePickerProps, Drawer, Form, Input, Space, Tabs } from "antd";
import { iClienteData } from "../../../Types/Types";
import { toast } from "react-toastify";
import SelectSites from "../../../Components/Select/selectSites";
import SelectGruposCliente from "../../../Components/Select/selectGruposClientes";
import api from "../../../helpers/axios";
import { ADMID, getSiteID } from "../../../Components/utils/exportVariables";
import { userContext } from "../../../context/usuarioContext";


export default function Clientes() {
    const { user } = useContext(userContext);
    const [isAdmin, setIsAdmin] = useState(false);
    const [tableData, setTableData] = React.useState<iClienteData[]>([]);
    
    React.useEffect(() => {
        
    
        api.get( user && user.isADM 
            ? '/clientes' : `/cliente/site/${ user?.idSite._id}`).then((response) => {
            
            setTableData(response.data);
        });
    }, [user]);

    const [isDrawerOpen, setisDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        clearInputs();
        setisDrawerOpen(!isDrawerOpen);
        setIsEdit(false);
    };
    const [selectValue, setSelectValue] = useState<string>("");
    const [selectValueGrupo, setSelectValueGrupo] = useState<string[]>([]);
    const [nome, setNome] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [telefone, setTelefone] = useState<string>("");
    const [nascimento, setNascimento] = useState<Date>(new Date());
    const [atualizaID, setAtualizaID] = useState<string>("");
    const [isEdit, setIsEdit] = useState<boolean>(false)
    function edit(id: string) {
        setIsEdit(true);
        api.get(`/cliente/${id}`).then((response) => {
            const res = response.data as iClienteData;
            console.log("responseData", response.data)
            const grupos: string[] = [];
            setAtualizaID(res._id.toString());
            setNome(res.nome);
            setEmail(res.email);
            setTelefone(res.telefone);
            setNascimento(res.dataNascimento);
            setSelectValue(res.idSite as unknown as string);
            // res.idGrupo.map( item=> {
            //     grupos.push(item._id.toString())
            // })
            setSelectValueGrupo(res.idGrupo as unknown as string[]);
        });
        setisDrawerOpen(true);
    }
    function cadastro() {
        return api.post('/cliente', {
            nome: nome,
            email: email,
            telefone: telefone,
            dataNascimento: nascimento,
            idGrupo: selectValueGrupo,
            idSite:  user && user.isADM  ? selectValue : user?.idSite._id,
        });
    }
    function atualizar() {
        return api.put(`/cliente/${atualizaID}`, {
            nome: nome,
            email: email,
            telefone: telefone,
            dataNascimento: nascimento,
            idGrupo: selectValueGrupo,
            idSite:  user && user.isADM  ? selectValue : user?.idSite._id,
        });
    }
    async function OnSubmit() {
        if (!isEdit) {
            try {
                const resposta = await cadastro();
                toast.success("Cadastrado com Sucesso");
               
            } catch (error) {
                toast.error(`${error}`);
            }
        } else {
            try {
                const resposta = await atualizar();
           
                toast.success("Atualizado com Sucesso");
               
            } catch (error) {
                toast.error(`${error}`);
            }
         
        }
        api.get( user && user.isADM 
            ? '/clientes' : `/cliente/site/${ user?.idSite._id}`).then((response) => {
            
            setTableData(response.data);
        });
        
        toggleDrawer();

    }
    function clearInputs() {
        setSelectValue("");
        setNome("");
        setEmail("");
        setTelefone("");
        setSelectValueGrupo([]);
        setNascimento(new Date());
    }
    const onChangeDatePicker: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
        setNascimento(new Date(dateString));
    };
    return (
        <MainCard title="Clientes">
            <div className="grid grid-cols-4 gap-4">
                <div className="col-span-4 text-right">
                    <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
                        Novo Cliente
                    </Button>
                </div>
                <div className="col-span-4">
                    <Card size='small'>
                        <ClienteTabela Data={tableData} setData={setTableData} edit={edit} />
                    </Card>

                </div>
            </div>
            <Drawer
                title="Cadastro de Cliente"
                placement="right"
                onClose={toggleDrawer}
                open={isDrawerOpen}
                extra={
                    <Space>
                        <Button onClick={toggleDrawer}>Cancel</Button>
                        <Button htmlType="submit" form="FromCad" type="primary">
                            Salvar
                        </Button>
                    </Space>
                }
            >
                <Form
                    layout="vertical"
                    onFinish={(e) => { OnSubmit(); }}
                    id="FromCad"
                >
                    <Form.Item label="Nome" rules={[{ required: true }]}>
                        <Input size="large" id="Nome" placeholder="Nome" type="text" value={nome} onChange={(e) => setNome(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Email" >
                        <Input required id="email" size="large" placeholder="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </Form.Item>
                    <Form.Item label="Telefone" >
                        <Input required id="telefone" size="large" placeholder="(__)_____-____" value={telefone} onChange={(e) => setTelefone(e.target.value)} />
                    </Form.Item>
                    {/* <Form.Item label="Data de Nascimento" >
                        <DatePicker id="nascimento" size="large" placeholder="Date de nascimento" onChange={onChangeDatePicker} />
                    </Form.Item> */}
                    <Form.Item label="Grupo" >
                        <SelectGruposCliente value={selectValueGrupo} setValue={setSelectValueGrupo} />
                    </Form.Item>
                    {
                         user && user.isADM 
                         ?
                            <Form.Item label="Site" >
                                <SelectSites value={selectValue} setValue={setSelectValue} />
                            </Form.Item>
                            :
                            null
                    }

                </Form>
            </Drawer>
        </MainCard>

    )
}