import { Button, Card, List, Popconfirm, Space } from 'antd';
import Meta from 'antd/es/card/Meta';
import { Image } from 'antd';
import React, { useState } from 'react';
import { FaPen, FaTrash } from 'react-icons/fa';
import { IProdutoData } from '../../../Types/Types';
import { AxiosError } from 'axios';
import api from '../../../helpers/axios';
import { toast } from 'react-toastify';
interface props {
    Data: IProdutoData[];
    setData: Function;
    editFuncion: Function
}

export default function ProdutosList({ Data, setData, editFuncion }: props) {
    async function startDelete(id: string) {
        await api.delete(`/produtos/${id}`)
            .then((response) => {
                toast.success("Produto excluido com Sucesso !")
                // console.log(response);
                api.get('/produtosPorCategoria').then((response) => {
                    console.log(response.data)
                    setData(response.data);
                });
            })
            .catch((error: AxiosError) => {
                console.error(error);
                toast.error(error.message);
            });
    }

    async function editar(id: string) {
        await editFuncion(id);
    }
    return (
        <List
            grid={{ gutter: 16, column: 4 }}
            dataSource={Data}
            renderItem={(item) => (
                <List.Item key={item._id}>
                    <Card
                        cover={
                            <Image
                                alt={item.nome}
                                src={item.imagem}
                                style={{ padding: "10px",maxHeight: "270px",minHeight: "270px"}}
                            />
                        }
                        actions={[
                            <FaPen key="edit" size={15} onClick={() => editar(item._id)} />,
                            <Popconfirm
                                title="Deletar Usuario !"
                                description="Tem certeza que deseja deletar ?"
                                onConfirm={() => startDelete(item._id)}
                                // onCancel={handleOpenCloseModalConfirm}
                                okText="Sim"
                                cancelText="Não"
                            >
                                <Button type="text" danger><FaTrash /></Button>
                            </Popconfirm>
                        ]}
                    >
                        <Meta
                            title={item.nome}
                            description={item.descricao.substring(0, 60) + "..." + item.preco}
                        />

                    </Card>
                </List.Item>
            )}
        />
    )
}