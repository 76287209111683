import axios from "axios";
import React, { SelectHTMLAttributes, useContext, useState } from "react";
import { iGrupoCliente, iOpt, } from "../../Types/Types";
import { Select } from "antd";
import api from "../../helpers/axios";
import { userContext } from "../../context/usuarioContext";

type iProps = SelectHTMLAttributes<HTMLSelectElement> & {
    placeHolder?: string;
    disabled?: boolean;
    label?: string;
    required?: boolean;
    value: string[] | null;
    setValue: Function;
    execOnChange?: Function
}

export default function SelectGruposCliente({ value, label, disabled, setValue,execOnChange, ...rest }: iProps) {
    const [options, setOptions] = useState<iOpt[]>([]);
    const { user } = useContext(userContext);
    React.useEffect(() => {
        api.get( user && user.isADM 
            ?'/grupoClientes':`/grupoCliente/site/${ user?.idSite._id}`).then((response) => {
            const tempOpt = [] as iOpt[];
            (response.data as iGrupoCliente[]).map(item => {
                tempOpt.push({
                    value: item._id.toString(),
                    label: item.nome.toString()
                })
            })
            setOptions(tempOpt);
        });

    }, []);
    const handleChange = (value: string[]) => {
        console.log(`Selected: ${value}`);
        if(execOnChange){
            execOnChange(value);
        }
        setValue(value)
    };
    return (
        <div>
            {label ? (
                <label
                    className="block mb-2 text-sm text-navy-700 font-bold"
                >
                    {label}
                </label>
            ) : (
                ''
            )}
            <Select
                mode="multiple"
                allowClear
                size="large"
                placeholder="Grupos"
                // defaultValue={[]}
                onChange={handleChange}
                value={value}
                style={{ width: "100%" }}
                options={options}
            />
        </div>
    );
}
