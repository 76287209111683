import React, { useContext, useState } from "react";
import MainCard from "../../../Components/MainCard";
import axios from "axios";
import {
  Button,
  Card,
  ColorPicker,
  DatePicker,
  DatePickerProps,
  Drawer,
  Form,
  Input,
  Space,
  Tabs,
} from "antd";
import { iGrupoCliente } from "../../../Types/Types";
import { toast } from "react-toastify";
import SelectSites from "../../../Components/Select/selectSites";
import GrupoTable from "../../../Components/Table/GrupoTable";
import api from "../../../helpers/axios";
import { isAdm } from "../../../Components/utils/exportVariables";
import { userContext } from "../../../context/usuarioContext";

export default function Clientes() {
  const { user } = useContext(userContext);
  const [isAdmin, setIsAdmin] = useState(isAdm() || false);
  const [tableData, setTableData] = React.useState<iGrupoCliente[]>([]);
  React.useEffect(() => {
    api
      .get(
        user && user.isADM 
          ? "/grupoClientes"
          : `/grupoCliente/site/${ user?.idSite._id}`
      )
      .then((response) => {
       
        setTableData(response.data);
      });
  }, [isAdmin, user]);

  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    clearInputs();
    setisDrawerOpen(!isDrawerOpen);
    setIsEdit(false);
  };
  const [selectValue, setSelectValue] = useState<string>("");
  const [nome, setNome] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [cor, setCor] = useState<string>("");

  function cadastro() {
    return api.post("/grupoCliente", {
      nome: nome,
      descricao: descricao,
      idSite: isAdmin ? selectValue : user?.idSite._id,
      cor: cor,
    });
  }

function edicao(){
  return api.put(`/grupoCliente/${atualizaID}`,{

    id:atualizaID,
    nome: nome,
    descricao: descricao,
    cor:cor,
    idSite:user && user.isADM ? selectValue : user?.idSite._id ,
  });
}

  async function OnSubmit() {
    try {
      console.log(isEdit);
      if(!isEdit)
      {
      const resposta = await cadastro();
      toast.success("Cadastrado com Sucesso");
    }
      else{
        const resposta = await edicao();
      toast.success("Editado com Sucesso");
      }
      
      api
      .get(
        user && user.isADM 
          ? "/grupoClientes"
          : `/grupoCliente/site/${user?.idSite ? user.idSite._id : 0}`
      )
      .then((response) => {
       
        setTableData(response.data);
      }); 

      toggleDrawer();
    } catch (error) {
      toast.error(`${error}`);
    }
  }
  function clearInputs() {
    setSelectValue("");
    setNome("");
    setDescricao("");
  }

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [atualizaID, setAtualizaID] = useState<string>("");
  function edit(id: string) {
    setIsEdit(true);
    api.get(`/grupoCliente/${id}`).then((response) => {
      const res = response.data as iGrupoCliente;
      setAtualizaID(res._id.toString());
      setNome(res.nome);
      setDescricao(res.descricao);
      setSelectValue(res.idSite._id.toString());
      setCor(res.cor);
    });
    setisDrawerOpen(true);
  }
  return (
    <MainCard title="Grupo de clientes">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 text-right">
          <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
            Novo Grupo
          </Button>
        </div>
        <div className="col-span-4">
          <Card size="small">
            <GrupoTable Data={tableData} setData={setTableData} edit={edit} />
          </Card>
        </div>
      </div>
      <Drawer
        title="Cadastro de Cliente"
        placement="right"
        onClose={toggleDrawer}
        open={isDrawerOpen}
        extra={
          <Space>
            <Button onClick={toggleDrawer}>Cancel</Button>
            <Button htmlType="submit" form="FromCad" type="primary">
              Salvar
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          onFinish={(e) => {
            OnSubmit();
          }}
          id="FromCad"
        >
          <Form.Item label="Nome" rules={[{ required: true }]}>
            <Input
              required
              size="large"
              id="Nome"
              placeholder="Nome"
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Descrição">
            <Input
              required
              id="descrição"
              size="large"
              placeholder="Descrição"
              type="text"
              value={descricao}
              onChange={(e) => setDescricao(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Cor">
            <ColorPicker
              format="hex"
              value={cor}
              onChange={(e) => setCor(`#${e.toHex()}`)}
            />
          </Form.Item>
          { user && user.isADM 
          ? (
            <Form.Item label="Site">
              <SelectSites value={selectValue} setValue={setSelectValue} />
            </Form.Item>
          ) : null}
        </Form>
      </Drawer>
    </MainCard>
  );
}
