import React, { useContext, useState } from "react";
import MainCard from "../../Components/MainCard";
import axios from "axios";
import UserTabela from "../../Components/Table/UserTable";
// import Button from "../../Components/Button";
// import Input from "../../Components/Input";
import { Button, Drawer, Form, Input, Space, Switch } from "antd";
import { iOpt, iPermissoesData, iUserData } from "../../Types/Types";
import { toast } from "react-toastify";
// import { Label } from "@headlessui/react/dist/components/label/label";
import SelectSites from "../../Components/Select/selectSites";
import api from "../../helpers/axios";
import SelectPermissoes from "../../Components/Select/selectPermissao";
import { userContext } from "../../context/usuarioContext";
import { ADMID } from "../../Components/utils/exportVariables";

export default function Usuario() {
  const { user } = useContext(userContext);
  const [users, setUsers] = React.useState<iUserData[]>([]);
  React.useEffect(() => {
    if (user?._id)
      if (user.isADM) {
        api.get(`/usuarios`).then((response) => {
          console.log(response.data);
          setUsers(response.data);
        });
      } else {
        api.get(`/usuario/site/${user?.idSite._id}`).then((response) => {
          console.log("aaaaaa");
          console.log(response.data);
          setUsers(response.data);
        });
      }
  }, [user]);

  const [isDrawerOpen, setisDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsEdit(false);
    clearInputs();
    setisDrawerOpen(!isDrawerOpen);
  };
  const [idSite, setIdSite] = useState<string>("");
  const [idPermissao, setIdPermissao] = useState<string[]>([]);
  const [nome, setNome] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [adm, setAdm] = useState<boolean>(false);

  function cadastro() {
    return api.post("/usuario", {
      nome: nome,
      email: email,
      senha: senha,
      idPermissao: idPermissao,
      isADM: adm,
      idSite: user && user.isADM ? idSite : user?.idSite._id,
    });
  }
  function atualizar() {
    return api.put(`/usuarios/${atualizaID}`, {
      nome: nome,
      email: email,
      isADM: adm,
      idPermissao: idPermissao,
      idSite: user && user.isADM ? idSite : user?.idSite._id,
    });
  }
  async function OnSubmit() {
    console.log(isEdit);
    if (!isEdit) {
      try {
        const resposta = await cadastro();
        console.log(resposta);
        toast.success("Cadastrado com Sucesso");
      } catch (error) {
        console.log(error);
        toast.error(`${error}`);
      }
    } else {
      try {
        const resposta = await atualizar();
        api.get("/clientes").then((response) => {
          console.log(response.data);
        });
        toast.success("Atualizado com Sucesso");
      } catch (error) {
        toast.error(`${error}`);
      }
    }
    toggleDrawer();
    if (user?._id)
      if (user.idPermissao.filter((item) => item._id === ADMID).length > 0) {
        api.get(`/usuarios`).then((response) => {
          console.log(response.data);
          setUsers(response.data);
        });
      } else {
        api.get(`/usuario/site/${user?.idSite._id}`).then((response) => {
          console.log(response.data);
          setUsers(response.data);
        });
      }
  }
  function clearInputs() {
    setIdSite("");
    setNome("");
    setEmail("");
    setIdPermissao([]);
    setSenha("");
  }
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [atualizaID, setAtualizaID] = useState<string>("");
  function edit(id: string) {
    setIsEdit(true);
    api.get(`/usuario/${id}`).then((response) => {
      const res = response.data as iUserData;
      setAtualizaID(res._id.toString());
      setNome(res.nome);
      setEmail(res.email);
      setSenha(res.senha);
      setIdSite(res.idSite._id.toString());
      setAdm(res.isADM);
      setIdPermissao(res.idPermissao.map((item) => item._id));
    });
    setisDrawerOpen(true);
  }
  const onChangeAdm = (checked: boolean) => {
    setAdm(checked);
  };
  return (
    <MainCard title="Usuario">
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4 text-right">
          <Button type="primary" size="large" key="back" onClick={toggleDrawer}>
            Novo Usuarios
          </Button>
        </div>
        <div className="col-span-4">
          <UserTabela Data={users} setData={setUsers} edit={edit} />
        </div>
      </div>
      <Drawer
        title="Cadastro de Usuário"
        placement="right"
        onClose={toggleDrawer}
        open={isDrawerOpen}
        extra={
          <Space>
            <Button onClick={toggleDrawer}>Cancel</Button>
            <Button htmlType="submit" form="FromCad" type="primary">
              Salvar
            </Button>
          </Space>
        }
      >
        <Form
          layout="vertical"
          onFinish={(e) => {
            OnSubmit();
          }}
          id="FromCad"
        >
          <Form.Item label="Nome">
            <Input
              required
              size="large"
              id="Nome"
              placeholder="Nome"
              type="text"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </Form.Item>
          <Form.Item label="Email">
            <Input
              required
              id="email"
              size="large"
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Item>
          {!isEdit ? (
            <Form.Item label="Senha">
              <Input.Password
                required
                id="senha"
                size="large"
                placeholder="Senha"
                type="password"
                value={senha}
                onChange={(e) => setSenha(e.target.value)}
              />
            </Form.Item>
          ) : null}

          <Form.Item label="Permissões">
            <SelectPermissoes
              value={idPermissao}
              setValue={setIdPermissao}
              adm={user && user.isADM}
            />
          </Form.Item>
          {user && user.isADM ?
            <Form.Item label="É um administrador" >
              <Switch checked={adm} onChange={onChangeAdm} />
            </Form.Item>
            :
            ""
          }
          {user && user.isADM ? (
            <Form.Item label="Site">
              <SelectSites value={idSite} setValue={setIdSite} />
            </Form.Item>
          ) : null}
        </Form>
      </Drawer>
    </MainCard>
  );
}
