import { Collapse, CollapseProps, Divider, List, Space } from "antd";
import React, { CSSProperties, useState } from "react";
import { iImagensListaCategoria, iPosts } from "../../Types/Types";
import { Typography } from "antd";
import api from "../../helpers/axios";
import ImageCard from "./components/ImageCard";
import { text } from "stream/consumers";
const { Title } = Typography;
type props = {
  data: iImagensListaCategoria[];
  edit: Function;
  del: Function;
};
export default function ImageList({ data, edit, del }: props) {
  console.log(data);
  const { Panel } = Collapse;
  return (
    <>
      {data.map((cate) => (
        <>
          {/* <Divider orientation="left" className='py-10'>{cate.Categoria}</Divider> */}
          <Collapse defaultActiveKey={data[0].categoria} ghost size="large">
            <Panel header={<>{cate.categoria} <hr /></>} key={cate.categoria}>
              <List
                grid={{ gutter: 16, column: 4 }}
                dataSource={cate.imagens}
                renderItem={(item) => (
                  <List.Item key={item._id}>
                    <ImageCard
                      Titulo={item.titulo}
                      descricao={item.descricao}
                      categoria={item.categoria}
                      imagem={item.imagem}
                      id={item._id}
                      edit={edit}
                      del={del}
                    />
                  </List.Item>
                )}
              />
            </Panel>
          </Collapse>
        </>
      ))}
    </>
  );
}

