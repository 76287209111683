import React from 'react';
import { Button, Card, Popconfirm, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { iUserData } from '../../../Types/Types';
import { FaPen, FaTrash } from 'react-icons/fa';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import api from '../../../helpers/axios';
import { useContext } from 'react';
import { userContext } from '../../../context/usuarioContext';

type TableProps = {
    Data: iUserData[];
    setData: Function;
    edit: Function;
};

export default function TableSite({ Data, setData, edit }: TableProps) {
    const { user } = useContext(userContext);
    async function startDelete(id: string) {
        await api.delete(`/usuario/${id}`)
            .then((response) => {
                toast.success("Usuário excluido com Sucesso !")
                // console.log(response);
                setData(Data.filter(item => item._id !== id));
            })
            .catch((error: AxiosError) => {
                console.error(error);
                toast.error(error.message);
            });
    }
    const columns: ColumnsType<iUserData> = [
        {
            title: 'Nome Usuario',
            dataIndex: 'nome',
            key: 'nome',
            width: '5%',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            width: '5%',
        },
        {
            title: 'Status',
            key: 'ativo',
            dataIndex: 'ativo',
            width: '5%',
            render: (_, { ativo }) => (
                <>
                    <Tag color={ativo ? "#87d068" : "volcano"}>
                        {ativo ? "Ativo" : "Inativo"}
                    </Tag>
                </>
            ),
        },
        {
            title: 'Permissões',
            dataIndex: 'idPermissoes.nome',
            key: 'empresa',
            width: '10%',
            render: (_, { idPermissao }) => (
                <>
                    {idPermissao.map(item=>{
                        return <Tag color={item.cor}> {item.nome} </Tag>;
                    })}
                </>
            ),
        },
        {
            title: 'Site',
            dataIndex: 'idEmpresa.nome',
            key: 'idSite',
            width: '10%',
            render: (_, { idSite }) => (
                <>
                    {idSite.nome}
                </>
            ),
        },
        {
            title: '',
            key: 'action',
            width: '1%',
            render: (_, row) => (
                <Space size="middle">
                    <Button type="text" onClick={() => edit(row._id)}><FaPen /></Button>
                    <Popconfirm
                        title="Deletar Usuario !"
                        description="Tem certeza que deseja deletar ?"
                        onConfirm={() => startDelete(row._id)}
                        // onCancel={handleOpenCloseModalConfirm}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="text" danger><FaTrash /></Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];
    return (
        <Card size='small'>
            <Table columns={user && user.isADM ? columns : columns.filter(item=> item.key !== "idSite")} dataSource={Data} />
        </Card>

    )
}
