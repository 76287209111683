import { Tabs, TabsProps } from "antd";
import TabProduto from "./components/Produtos"
import MainCard from "../../Components/MainCard";

export default function Produtos() {
    return (
        <MainCard title={""}>
            <TabProduto />
        </MainCard>
    )
}